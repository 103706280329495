<template>
  <div class="item d-flex flex-column text-center" ref="item" v-if="getItem" :class="{
    'border-white-25 mx-2 border px-2 cursor-pointer': chatMessage
  }">
    <v-tooltip :shown="forceTooltip" popper-class="item-tooltip-popout">
      <img :src="getItemImage" :alt="getItemImage" class="mc-item-image" :style="[`--size: ${size}px`]"/>
      <span v-if="chatMessage" class="ms-1">{{ itemName }}</span>
      <span class="badge bg-gradient amount" v-if="!hideQuantity && itemQuantity"
            :class="{
              'bg-secondary' : !this.invalidAmount,
              'bg-danger': this.invalidAmount
            }">x{{ itemQuantity }}</span>


      <template #popper>
        <div :style="calculateTier" class="glow-border">
          <div class="bg-black z-index-5 position-relative">
            <div class="d-flex flex-row align-items-center">
              <div>
                <div class="d-flex flex-row align-items-center">
                  <img :src="getItemImage" :alt="getItemImage" class="pe-2 image-pixelated"/>
                  <h5 class="mb-0">{{ itemName }}</h5>
                </div>
                <h6 v-if="getItem.amount > 1">x{{ getItem.amount }}</h6>
                <p v-if="description" v-html="description"></p>
                <h6 class="my-2 text-white-50" v-if="statsTable.length > 0">Attributes</h6>
              </div>
              <div class="ms-auto refine-level" v-if="refineLevel">
                <span class="fs-3 p-2">+{{ refineLevel.value }}</span>
              </div>
            </div>
            <table
                class="table table-borderless text-white table-bordered border-dark item-stats-table align-middle w-100 my-2">
              <tbody>
              <tr v-for="(stat, index) in statsTable" :key="index">
                <td class="d-flex align-items-center attribute-column">
                  <img :src="getStatIcon(stat.attribute)" class="me-2 image-pixelated" height="24" width="24"
                       alt="attribute icon" v-if="getStatIcon(stat.attribute)">
                  {{ formatAttribute(stat.attribute) }}
                </td>
                <td class="text-end" v-html="formatValue(stat.attribute, stat.value)"></td>
              </tr>
              <tr v-for="(stat, index) in enchantments" :key="index">
                <td>{{ stat.enchantment_name }}</td>
                <td>{{ stat.enchantment_strength }}</td>
              </tr>
              <tr v-if="getItem.Item?.health_given">
                <td>
                  <img src="/img/icons/heart.png" class="me-2 image-pixelated" height="24" width="24"
                       alt="attribute icon"/>
                  Health Given
                </td>
                <td>
                  {{ getItem.Item.health_given }}
                </td>
              </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-between">
              <span>
              <img src="/img/sprites/coin.png"/> {{ itemValue }}
              </span>

              <span v-if="getItem.Item.heat">
                <img src="/img/sprites/flame.png" />
                {{ getItem.Item.heat }}
              </span>

            </div>
          </div>
        </div>
      </template>
    </v-tooltip>
  </div>
</template>
<style lang="scss">
.item-tooltip-popout {
  width: 320px;
  max-width: 100%;
}
.v-popper__popper {
  font-size: 14px;

  backdrop-filter: blur(10px) saturate(180%);
  -webkit-backdrop-filter: blur(10px) saturate(180%);
  box-sizing: border-box;
  border-radius: 7px;

  .v-popper__inner {
    padding: 0;
    overflow: hidden;

    > div {
      padding: 15px;
    }
  }

  &.v-popper--theme-dropdown {

    .v-popper__arrow-inner, .v-popper__arrow-outer {
      border-color: black;
    }

    .v-popper__inner {

      background: unset !important;
      color: unset !important;
      border: unset !important;
    }
  }

  .v-popper__wrapper {
    overflow: hidden;
    //border: 1px white solid;
    background: black;
    border-radius: 7px;
    border: 5px solid black;
    //padding: 10px 15px;

    .v-popper__inner {

    }

    .glow-border {
      padding: 2px;
      margin: -14px -14px;

      .refine-level {
        --color-start: white;
        background: linear-gradient(45deg,
            var(--color-start) 0%,
            var(--color) 15%,
            var(--color-start) 30%,
            var(--color) 45%,
            var(--color-start) 60%,
            var(--color) 75%,
            var(--color-start) 100%) transparent;

        background-clip: text;
        //color: var(--color);
        color: transparent;
        background-size: 200%;
        transition: all ease-in-out 5s;
        animation: tshine 30s infinite linear;
      }

      > div {
        padding: 10px 15px;
        border: 5px solid black;
        border-radius: 7px;
      }

      &:after {
        content: " ";
        height: 200%;
        width: 200%;
        position: absolute;
        transform: rotate(0deg);
        left: -1px;
        top: -1px;
        background: linear-gradient(45deg,
            var(--color-start) 0%,
            var(--color) 15%,
            var(--color-start) 30%,
            var(--color) 45%,
            var(--color-start) 60%,
            var(--color) 75%,
            var(--color-start) 100%) transparent;
        background-size: 200%;
        transition: all ease-in-out 2s;
        animation: tshine 10s infinite linear;
        z-index: 1;
        opacity: 0.7;
        pointer-events: none;
      }

      @keyframes tshine {
        0% {
          background-position: 0 0;
        }
        50% {
          background-position: 150%;
        }
        100% {
          background-position: 0 0;
        }
      }
    }

    .v-popper__inner {
      position: relative;
      border-radius: 7px;

    }
  }

  .attribute-column {
    img {
      margin-right: 15px;
    }
  }
}

.item {
  display: flex;
  position: relative;
  font-size: 12px !important;

  .amount {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 10px;
    padding: 2px 5px;
  }
}


.rainbowText {
  background-color: #1fe3c8;
  background-image: linear-gradient(45deg, transparent, #02e399, rgba(0, 0, 0, 0.3), #02e399, transparent) !important;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: move 10s infinite linear;
}

@keyframes move {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100vh;
  }
}

</style>
<script>

import {startCase} from "lodash/string";
import {mapGetters} from "vuex";

export default {
  name: 'Item',
  data() {
    return {
      itemFromHash: {},
      loading: false,
    }
  },
  props: {
    item: {
      type: Object,
      required: false,
    },
    hash: {
      type: String,
      required: false,
    },
    listing: {
      type: Boolean,
      required: false,
    },
    chatMessage: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: Number,
      default: 64
    },
    quantity: {
      type: Number,
    },
    invalidAmount: {
      type: Boolean,
      default: false
    },
    hideQuantity: {
      type: Boolean,
      default: false,
    },
    events: {
      type: Boolean,
      default: false
    },
    animate: {
      type: Boolean,
      default: false,
    },
    forceTooltip: {
      type: Boolean,
      default: false,
    }
  },
  created() {

    if(this.hash && !this.item) {
      this.loadData();
    }
  },
  watch: {
    realQuantity(newValue, oldValue) {

      if (!this.animate) return;

      // Increased stack
      if (newValue > oldValue) {

        this.$refs['item'].classList.add('item-stack-increase-animation');

        setTimeout(() => {
          this.$refs['item'].classList.remove('item-stack-increase-animation');
        }, 1500)
      } else if (newValue < oldValue) {


        this.$refs['item'].classList.add('item-stack-decrease-animation');

        setTimeout(() => {
          this.$refs['item'].classList.remove('item-stack-decrease-animation');
        }, 1500)
      }
    }
  },
  methods: {
    loadData() {
      if (this.loading) return;

      this.loading = true;

      const item = this.$store.getters["itemProfileStore/items"](
        this.hash
      );

      if (item) {
        this.itemFromHash = item;
        this.loading = false;
      } else {
        this.$socket.emit('auth:get item profile', {
          hash: this.hash,
        }, (item) => {
          this.itemFromHash = item;
          this.loading = false;
        })
      }
    },
    formatAttribute(attr) {

      if (attr === 'levelRequirement')
        attr = 'Requires'

      return startCase(attr)
    },
    getStatIcon(stat) {
      stat = stat.toLowerCase();
      switch (stat) {
      case 'attack':
        return `/img/items/IronSword.png`
      case 'defense':
        return `/img/items/IronChestplate.png`
      }


      if (stat.includes('speed')) {
        return `/img/items/Clock.png`
      }

      if (stat === 'levelrequirement') {
        return `/img/sprites/bolt.png`
      }

      return false;
    },
    formatValue(stat, value) {


      if (stat.startsWith('levelRequirement')) {
        const name = value.split(':')
        const level = value[1];
        const skill = value[0];

        const get_level = this.currentLevel(skill);

        let string = "";

        if (get_level < level) {
          string += `<span class="text-danger">${name[1]} ${name[0]}</span>`
        }

        if (get_level < level) {
          string += `<span class="text-success">${name[1]} ${name[0]}</span>`
        }

        if (string === "")
          string = `${name[1]} ${name[0]}`;

        return `${string}`
      }

      if (stat === 'speed') {
        return `${(5000 - value) / 1000}s`;
      }

      if (stat.toLowerCase().includes('speed')) {
        value = Number(value).toFixed(2);
        if (value > 0)
          return `<span class='text-success'>-${value}%</span>`;
        else if (value < 0)
          return `<span class='text-danger'>+${value - (value * 2)}%</span>`;
      }
      return value;
    },
    kFormatter(num) {
      return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    }
  },
  computed: {
    ...mapGetters({
      currentLevel: "user/getLevelForSkill"
    }),

    calculateTier() {

      let refineLevel = this.getAttributes?.find(s => s.attribute === 'refineLevel')?.value;

      if (!refineLevel)
        return `margin: -20px`;

      refineLevel = Number(refineLevel);

      if (refineLevel < 3)
        return `--color: white;--color-start:black;`
      else if (refineLevel < 6)
        return `--color: lime;--color-start:white;`
      else if (refineLevel < 9)
        return `--color: lightgreen;--color-start:white;`
      else if (refineLevel < 12)
        return `--color: cyan;--color-start:black;`
      else if (refineLevel < 15)
        return `--color: orange;--color-start:black;`
      else if (refineLevel < 18)
        return `--color: red;--color-start:black;`
      else if (refineLevel < 21)
        return `--color: purple;--color-start:black;`
      else if (refineLevel < 23)
        return `--color: cyan;--color-start:blue;`
      else if (refineLevel < 25)
        return `--color: orange;--color-start:purple;`
      else if(refineLevel < 27)
        return `--color: red;--color-start:purple;`
      else if (refineLevel < 28)
        return `--color: red;--color-start:gold;`
      else if (refineLevel < 29)
        return `--color: gold;--color-start:cyan;`
      else if (refineLevel > 28)
        return `--color: gold;--color-start:purple;`

      return `margin: -20px`;

    },
    refineLevel() {
      return this.getAttributes?.find(s => s.attribute === 'refineLevel')
    },
    itemName() {
      if (this.getItem?.Item) {
        return this.getItem.Item.name
      }

      return this.getItem.name;
    },
    statsTable() {

      const ds = [
        'attack',
        'defense',
        'armor_penetration',
        'speed',
        'woodcuttingSpeed',
        'miningSpeed',
        'fishingSpeed',
        'craftingSpeed',
        'smeltingSpeed',
        'cookingSpeed',
        'levelRequirement',
      ];

      if (!this.getAttributes && this.getItem.Item) {
        const table = [];
        for (const i in this.getItem.Item) {
          const key = this.getItem.Item[i];
          if (ds.includes(i) && key) {
            table.push({
              attribute: i,
              value: key,
            });
          }
        }

        return table;
      }


      if (!this.getAttributes && typeof this.getItem === 'object') {
        const table = [];
        for (const i in this.getItem) {
          const key = this.getItem[i];
          if (ds.includes(i)) {
            table.push({
              attribute: i,
              value: key,
            });
          }
        }

        return table;
      }

      return this.getAttributes.filter(n => ds.includes(n.attribute))
    },
    itemValue() {
      return new Intl.NumberFormat().format(this.getItem.Item.value)
    },
    enchantments() {
      return this.getItem.ItemEnchantments;
    },
    description() {
      return this.getAttributes?.find(n => n.attribute === 'description')?.value;
    },
    validItem() {
      return Object.keys(this.getItem).length > 0;
    },
    itemQuantity() {
      const quantity = this.quantity || this.getItem.amount;

      return this.kFormatter(quantity)
    },
    getItemImage() {
      const name = this.itemName;
      return `/img/items/${startCase(name).replaceAll(' ', '')}.png`
    },
    realQuantity() {
      return this.quantity || this.getItem?.amount || 0;
    },
    getAttributes() {

      if(this.listing) {
        let attr = this.getItem.item_props;

        if(typeof attr === 'string')
          attr = JSON.parse(attr).ItemAttributes;
        else
          attr = attr.ItemAttributes;

        return attr;
      }

      return this.getItem.ItemAttributes;
    },
    getItem() {
      // if(this.item.item_props) {
      //   return this.item.item;
      // }
      return this.item || this.itemFromHash;
    }
  }
}
</script>
