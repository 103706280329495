<template>
  <div class="d-flex flex-column mh-100">
    <div class="d-flex flex-row justify-content-end" v-if="showPoseControls">
      <v-tooltip v-if="pose === 'combat'">
        <button @click="changePose('relaxed')" class="btn"><img src="/img/icons/relaxed.png"/></button>

        <template #popper>
          Relaxed pose
        </template>
      </v-tooltip>

      <v-tooltip v-else>
        <button @click="changePose('combat')" class="btn"><img src="/img/icons/combat.png"/></button>
        <template #popper>
          Combat Pose
        </template>
      </v-tooltip>

      <v-tooltip v-if="!cosmeticsPublic">
        <button @click="showCosmetics" class="btn"><img src="/img/items/Wardrobe.png"/></button>

        <template #popper>
          View with cosmetics on
        </template>
      </v-tooltip>

      <v-tooltip v-else>
        <button @click="hideCosmetics" class="btn"><img src="/img/items/Equip.png"/></button>

        <template #popper>
          View with armor on
        </template>
      </v-tooltip>
    </div>
    <div class="player-avatar" :class="[`pose-${pose}`, 'position-relative']">
      <img :src="`/img/skins/cropped/${pose}_` + image.name" v-for="(image, index) in images" :key="index"
           :alt="`/img/skins/cropped/${pose}_` + image.name" :class="[`${image.type}`]">
      <img :src="`/img/skins/cropped/${pose}_skin_${getSkin}.png`" class="skin"/>
    </div>
  </div>
</template>
<style scoped lang="scss">
.player-avatar {
  max-width: 100%;
  max-height: 100%;

  height: calc(100% - 50px);

  .helmet {
    z-index: 5;
  }

  .chestplate {
    z-index: 4;
  }

  .leggings {
    z-index: 3;
  }

  .boots {
    z-index: 4;
  }

  .weapon {
    z-index: 6;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .skin {
    z-index: -1;
  }

  > img:not(.skin) {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
<script>
import {mapGetters} from "vuex";
import {snakeCase} from 'lodash';

export default {
  name: "Player",
  data() {
    return {
      pose: 'relaxed',
      poses: ['relaxed', 'combat']
    }
  },
  props: {
    currentPose: {
      type: String,
      default: 'relaxed'
    },
    showPoseControls: {
      type: Boolean,
      default: false,
    },
    cosmeticsPublicOverride: {
      type: Boolean,
      default: false,
    },
    skin: {
      type: String,
      default: null,
    },
    equipment: {
      type: Array,
      required: false,
    },
    cosmetics: {
      type: Array,
      required: false,
    }
  },
  mounted() {
    this.pose = this.currentPose || 'relaxed';


  },
  computed: {
    getSkin() {
      return this.skin || this.playerSkin
    },
    getEquipment() {
      return this.equipment || this.items
    },
    getCosmetics() {
      return this.cosmetics || this.playerCosmetics
    },
    cosmeticsHidden() {
      return this.cosmeticsPublicOverride || this.cosmeticsPublic
    },
    ...mapGetters({
      items: "user/equippedItems",
      playerSkin: "user/skin",
      cosmeticsPublic: "user/cosmeticsPublic",
      playerCosmetics: "user/cosmeticsEquipped"
    }),
    images() {
      const images = [];

      const items = !this.cosmeticsHidden ? this.getItems : this.getCosmeticAssets;

      for (const item in items) {
        const i = this.cosmeticsHidden ? this.getCosmeticAssets[item] : this.getItems[item];
        const itemName = snakeCase(i?.Item?.name || i.Cosmetic.name) + '.png';
        if (i?.Item?.name.toLowerCase() !== 'arrow') {
          images.push({
            name: itemName,
            type: i?.Item?.type || i?.Cosmetic?.type,
          });
        }
      }
      return images;
    },
    getCosmeticAssets() {
      const types = [
        'helmet',
        'chestplate',
        'leggings',
        'boots',
        'weapon',
        'arrow',
      ];

      let cosmetics = [];


      types.map(type => {
        const f = this.getCosmetics.find(cos => cos.Cosmetic.type === type);

        if (f)
          cosmetics.push(f)
        else {
          const equipment = this.getEquipment.find(e => e.Item.type === type)

          if (equipment)
            cosmetics.push(equipment)
        }
      })

      return cosmetics;
    },
    getItems() {
      const visualItems = [
        'helmet',
        'chestplate',
        'leggings',
        'boots',
        'weapon',
        'arrow',
      ]
      return this.getEquipment.filter(item => {
        return visualItems.includes(item.Item.type)
      })
    }
  },
  methods: {
    changePose(pose) {

      if (this.poses.includes(pose))
        this.pose = pose;
    },
    showCosmetics() {
      this.$socket.emit('auth:show cosmetics')
    },
    hideCosmetics() {
      this.$socket.emit('auth:hide cosmetics')
    },
  }
}
</script>