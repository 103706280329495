import {levelHelpers} from "@/utils/levelHelper";

export const UserStore = {
  namespaced: true,
  state: () => ({
    character: {},
    inventory: {},
    unlocked_locations: {},
    relationships: {},
    cosmetics: [],
    user: {},
    refineChance: 0,
    userReady: false,
  }),
  mutations: {
    SOCKET_LOAD_USER(state, data) {
      state.user = data.user;
      state.character = data.character;
      state.inventory = data.inventory;
      state.unlocked_locations = data.unlocked_locations;
      state.relationships = data.relationships;
      state.cosmetics = data.cosmetics;
      state.refineChance = 0;
      state.userReady = true;


    },
    SOCKET_GAIN_XP(state, data) {
      state.character.UserLevel[`${data.skillName}_xp`] += data.xp;
    },
    SOCKET_LEVEL_UP(state, data) {
      state.character.UserLevel[`${data.skillName}_level`] = data.level
    },
    SOCKET_UPDATE_REFINE_ITEM(state, item) {
      const findRefineItem = state.inventory.findIndex(i => i.refine);

      state.inventory[findRefineItem] = item;
    },
    SOCKET_INVENTORY_ADD_ITEM(state, item) {

      let it = state.inventory.find(i => {
        if(!i.item_hash)
          return i.id === item.id;

        return i.id === item.id && item.item_hash === i.item_hash;
      });

      if (!it)
        state.inventory.push(item)
      else if (item.amount === 0) {
        const itID = state.inventory.findIndex(i => i.id === item.id)
        state.inventory.splice(itID, 1)
      } else {
        const itID = state.inventory.findIndex(i => i.id === item.id)
        state.inventory.splice(itID, 1)
        state.inventory.push(item);
      }
    },
    SOCKET_DEAD(state) {
      state.character.current_skill = null;
    },
    SOCKET_STOP_SKILL(state) {
      state.character.current_skill = null;
    },
    SOCKET_START_SKILL(state, skill) {
      state.character.current_skill = skill.id;
    },
    SOCKET_CHARACTER_UPDATE(state, data) {
      for (const stat in data) {
        state.character[stat] = data[stat];
      }
    },
    SOCKET_LOGOUT() {
      localStorage.removeItem(process.env.VUE_APP_JWT_NAME);
      window.location.reload()
    },
    SOCKET_UNEQUIP_ITEM(state, id) {

      const item = state.inventory.find(s => s.id === id)
      if (item)
        item.equipped = false;
    },
    SOCKET_GIVE_COSMETIC(state, cosmetic) {
      state.cosmetics.push(cosmetic)
    },
    SOCKET_EQUIP_COSMETIC(state, id) {

      const item = state.cosmetics.find(s => s.id === id)
      item.equipped = true;
    },
    SOCKET_UNEQUIP_COSMETIC(state, id) {

      const item = state.cosmetics.find(s => s.id === id)
      item.equipped = false;
    },
    SOCKET_SHOW_COSMETICS(state) {
      state.character.show_cosmetics = true;
    },
    SOCKET_HIDE_COSMETICS(state) {
      state.character.show_cosmetics = false;
    },
    SOCKET_QUEST_TRACKER_UPDATE(state, data) {
      let tracker = state.character.QuestTrackers.find(tracker => tracker.id === data.id);
      if (tracker)
        tracker.progress = data.progress;
    },
    SOCKET_START_QUEST(state, data) {
      state.character.QuestTrackers = data.trackers;
    },
    SOCKET_RESET_QUEST(state) {
      state.character.QuestTrackers = [];
    },
    SOCKET_RESET_QUEST_TRACKERS(state) {
      state.character.QuestTrackers = [];
    },
    SOCKET_SET_QUEST_TRACKERS(state, data) {
      state.character.QuestTrackers = data;
    },
    SOCKET_FINISH_QUEST(state) {
      state.character.QuestTrackers = [];
      state.character.last_quest = state.character.current_quest;
      state.character.current_quest = null;
      state.character.quest_step = 0;
    },
    SOCKET_REFINE_CHANCE(state, chance) {
      state.refineChance = chance;
    }
  },
  getters: {
    getMCHead(state) {
      const head = state?.character.mc_head || "MHF_Steve";
      const match = head.match(/([a-zA-Z0-9_]{1,16})/g);

      if (match.length === 1)
        return `https://www.mc-heads.net/head/${match[0]}/50`
      else
        return `https://www.mc-heads.net/head/MHF_Steve/50`
    },
    refineChance(state) {
      return state.refineChance;
    },
    currency(state) {
      return state.character.gold || 0
    },
    heat(state) {
      return state.character.heat
    },

    getLevelForSkill: (state) => (skill) => {
      return state.character?.UserLevel[skill + '_level'] || 1
    },
    getCurrentXpForSkill: (state) => (skill) => {
      return state.character.UserLevel[skill + '_xp'] || 0
    },
    getFormattedXpRequiredForNextLevel: (state) => (skill) => {
      // Next level is actually just current level because arrays start at 0

      const level = state.character.UserLevel[skill + '_level'];


      if (!level)
        return null;

      return levelHelpers.getRequiredXp(level)
    },

    getPercentToNextLevel: (state) => (skill) => {
      //
      if (!state.character.UserLevel) return;
      const currentLevel = state.character.UserLevel[skill + '_level'] - 1;
      const nextLevel = state.character.UserLevel[skill + '_level'];

      const requiredXp = levelHelpers.getRequiredXp(nextLevel)
      const currentBaseLevelXp = levelHelpers.getRequiredXp(currentLevel)
      const diff = requiredXp - currentBaseLevelXp

      const currentXp = state.character.UserLevel[skill + '_xp'] - currentBaseLevelXp;


      return (100 * currentXp) / diff
    },
    getCharacterName(state) {
      return state.character.username;
    },
    getCharacterId(state) {
      return state.character.id;
    },
    getSkills(state) {
      return Object.keys(state.character.UserLevel).filter(i => i.includes('_xp') && !i.includes('adventur')).map(i => i.replace("_xp", ''))
    },
    inventory(state) {
      return state.inventory.filter(item => !item.equipped)
    },
    equippedItems(state) {
      return state.inventory.filter(item => item.equipped)
    },
    activeSkill(state) {
      return state.character.current_skill;
    },
    activeSkillZone(state, getters, rootState) {
      return rootState.config.zones.find(z => z.id === getters.activeSkill);
    },
    skin(state) {
      return state.character.skin;
    },
    isReady(state) {
      return state.userReady
    },
    cosmetics(state) {
      return state.cosmetics;
    },
    cosmeticsEquipped(state) {
      return state.cosmetics.filter(c => c.equipped === true)
    },
    cosmeticsPublic(state) {
      return state.character.show_cosmetics
    },
    isGuestAccount(state) {
      return state.user.guest;
    },
    currentQuestId(state) {
      return state.character.current_quest
    },
    questTracker(state) {
      return state.character.QuestTrackers;
    },
    staffLevel(state) {
      return state.user.staff;
    },
    currentQuestStep(state) {
      return state.character.quest_step;
    },
    health(state) {
      return state.character.health;
    },
    maxHealth(state) {
      return state.character.max_health;
    },
    questStatus(state, getters, rootState) {

      let completed = true;
      const currentQuest = rootState.config.quest;
      const currentQuestStep = state.character.quest_step;

      if(!currentQuest)
        return;


      if(!getters.currentQuestId && currentQuest) {
        return 'available'
      } else if (!getters.currentQuestId)
        return 'none';

      state.character.QuestTrackers.map(tracker => {
        const requirements = currentQuest.steps[currentQuestStep].requirements.find(req => req.id === tracker.requirement);

        if (tracker.progress < requirements.amount)
          completed = false;
      })

      if (completed) {
        return 'completed'
      }

      return 'in progress';
    },
    character(state) {
      return state.character
    }
  }
}