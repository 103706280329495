<template>
  <nav class="navbar navbar-expand-lg bg-light position-sticky top-0 loggedout-nav">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand" href="#"><img src="/img/homepage/logo.png"/></router-link>
      <button class="navbar-toggler" type="button" @click="toggleNav">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" :class="{'show': navOpen}" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :to="{name: 'Splash'}" class="nav-link" aria-current="page" href="#">Home</router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://blog.idlecraft.io" target="_blank" rel="nofollow">Blog</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://blog.idlecraft.io" target="_blank" rel="nofollow">Changelog</a>
          </li>
        </ul>
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="ms-auto">
            <router-link :to="{name: 'Login'}" class="btn btn-primary">Login</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      navOpen: false,
    }
  },
  methods: {
    toggleNav() {
      this.navOpen = !this.navOpen
    },
    play() {
      const hasSession = localStorage.getItem(process.env.VUE_APP_JWT_NAME)
      if(hasSession) {
        this.$router.push('/play')
      }
    },
  },
  sockets: {
    'REGISTER_SUCCESS'(token) {
      localStorage.setItem(process.env.VUE_APP_JWT_NAME, token);
      this.$socket.emit('guest:auth', {token})
    },

    'login success'(token) {
      localStorage.setItem(process.env.VUE_APP_JWT_NAME, token);
      this.$socket.emit('guest:auth', {token})
    },

    'redirect to play'() {
      this.$router.push({
        name: 'Mining'
      })
    },
  }
}
</script>