const levelHelpers = {};

levelHelpers.xpTable = require('./xpTable');

// levelHelpers.buildXpTable = function (limit = 1000) {
// for (let i = 0; i < limit; i++) {
//   levelHelpers.xpTable.push([
//     this.getRequiredXp(i)
//   ]);
// }
// }

levelHelpers.getRequiredXp = function (level) {
  let baseCalc = (Math.ceil((100 * level) + ((level * level * 6.4) * level) * 2.2));

  if(level > 99) {
    baseCalc += (level + levelHelpers.getRequiredXp(level - 1))
  }

  return Math.floor(baseCalc);
}

levelHelpers.getXpDifferenceForLastLevel = function (level) {
  return this.getLevelXp(level) - this.getLevelXp(level - 1);
}

levelHelpers.getXpDifferenceForNextLevel = function (level) {
  return this.getLevelXp(level + 1) - this.getLevelXp(level);
}


module.exports = {levelHelpers};
