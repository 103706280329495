<template>
  <v-menu :shown="forceShow" :showTriggers="['click', 'touch']" :hideTriggers="['click']" ref="pop" :positioning-disabled="isMobile">
    <div @mouseover.once="loadData" @click.once="loadData" @touchend="loadData" class="cursor-pointer">
      <span :class="{'fw-bold mx-1': isMention}">{{ profile }}</span>
    </div>


    <template #popper>
      <div class="position-relative text-white">
        <div @click="hideBit" class="fs-1 position-absolute end-0 top-0 z-index-100 cursor-pointer p-4 d-lg-none">x</div>
        <span v-if="loading">Loading</span>
        <span v-else>
          <template v-if="!character">
            User not found
          </template>
          <template v-else>
            <div class="d-flex flex-column flex-lg-row">
              <div style="height: 280px;">
               <Player style="height: 100%;" :skin="character.skin" :cosmetics="character.cosmetics" :equipment="character.equipment" :cosmetics-public-override="character.showCosmetics"/>
              </div>
              <div>

              <h5>{{ character.username }}</h5>
              <h6 class="text-muted">{{ character.title }}</h6>
              <div class="container mb-4">
                <div class="row">
                  <div class="col-4 d-flex flex-row align-items-center p-2" v-tooltip="'Total Levels'">
                    <img src="/img/items/EpicEssence.png" />
                    {{ totalLevel }}
                  </div>
                  <div class="col-4 d-flex flex-row align-items-center p-2" v-for="(level, index) in character.levels"
                       :key="index"
                       v-tooltip="level.skill"
                  >
                    <McIcon :skill="level.skill" class="me-2"/>
                    {{ level.value }}
                  </div>
                </div>
              </div>

              <div>
                <button class="btn btn-primary px-2 py-1 me-2" @click="whisperUser">Whisper</button>
                <button class="btn btn-primary px-2 py-1" @click="mentionUser">Mention</button>
              </div>
            </div>
            </div>
          </template>
        </span>
      </div>
    </template>
  </v-menu>
</template>

<script>

import McIcon from "@/components/McIcon.vue";
import Player from "@/components/player/Player.vue";

export default {
  name: 'UserProfile',
  components: {Player, McIcon},
  props: ['profile', 'shown', 'isMention'],
  emits: ['whisper', 'mention'],
  data() {
    return {
      loading: false,
      character: null,
      forceShow: false,
    }
  },
  mounted() {
    if (this.shown) this.loadData();

    if (this.shown)
      this.forceShow = true;
  },
  methods: {
    loadData() {

      if (this.loading) return;

      this.loading = true;

      const profile = this.$store.getters["userProfiles/profiles"](
        this.rawName
      );

      if (profile) {
        this.character = profile;

        this.loading = false;
      } else {
        this.$socket.emit('auth:get user profile bit', {
          username: this.rawName
        }, (profile) => {
          this.character = profile;

          this.loading = false;
        })
      }
    },
    hideBit() {


      this.$refs.pop.hide({
        skipDelay: true
      })
    },
    whisperUser() {
      this.$store.commit("chat/ADD_WHISPER", this.rawName);
      this.forceShow = false;

      this.hideBit()
      document.querySelector("#chat-input-box").focus()
    },
    mentionUser() {
      this.$store.commit("chat/ADD_MENTION", this.rawName);
      this.forceShow = false;

      this.hideBit()

      document.querySelector("#chat-input-box").focus()
    },
  },
  computed: {
    rawName() {
      return this.profile.replace('@', '');
    },
    isSystem() {
      return this.message.system
    },
    isMobile() {

      return window.innerWidth < 992;
    },
    formattedMessage() {
      return this.message.message;
    },
    totalLevel() {
      let total = 0;
      this.character.levels.map(level => {
        total += Number(level.value)
      })

      return total;
    }
  },
}
</script>