export const LayoutStore = {
  namespaced: true,
  state: () => ({
    inventoryOpen: false,
    chatOpen: false,
    sideMenuOpen: false,
    itemContextMenu: {
      open: false,
      item: null,
    },
    upgradeFromGuestDialogue: false,
  }),
  getters: {
    itemContextMenuIsOpen: (state) => state.itemContextMenu.open === true,
    inventoryIsOpen: (state) => state.inventoryOpen,
    chatIsOpen: (state) => state.chatOpen,
    sideMenuIsOpen: (state) =>  state.sideMenuOpen,
    upgradeFromGuestDialogue: (state) => state.upgradeFromGuestDialogue
  },
  mutations: {
    toggleInventory(state) {
      state.inventoryOpen = !state.inventoryOpen

      if (state.chatOpen)
        state.chatOpen = false;

    },
    toggleChat(state) {
      state.chatOpen = !state.chatOpen
      if (state.inventoryOpen)
        state.inventoryOpen = false;
    },
    toggleSideMenu(state) {
      state.sideMenuOpen = !state.sideMenuOpen;
    },
    toggleMenuNavs(state) {
      state.inventoryOpen = false;
      state.chatOpen = false;
    }
  }
}