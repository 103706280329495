module.exports = [
  0,
  115,
  313,
  681,
  1302,
  2260,
  3642,
  5530,
  8009,
  11165,
  15081,
  19841,
  25531,
  32234,
  40036,
  49021,
  59272,
  70876,
  83915,
  98475,
  114641,
  132495,
  152124,
  173612,
  197042,
  222501,
  250071,
  279837,
  311885,
  346298,
  383161,
  422558,
  464574,
  509293,
  556801,
  607180,
  660517,
  716895,
  776398,
  839112,
  905121,
  974508,
  1047360,
  1123759,
  1203791,
  1287540,
  1375091,
  1466528,
  1561936,
  1661398,
  1765001,
  1872827,
  1984961,
  2101489,
  2222494,
  2348060,
  2478274,
  2613218,
  2752977,
  2897637,
  3047281,
  3201993,
  3361859,
  3526962,
  3697388,
  3873221,
  4054544,
  4241444,
  4434003,
  4632307,
  4836440,
  5046487,
  5262532,
  5484660,
  5712954,
  5947501,
  6188383,
  6435685,
  6689493,
  6949890,
  7216961,
  7490790,
  7771462,
  8059061,
  8353673,
  8655380,
  8964269,
  9280423,
  9603926,
  9934864,
  10273320,
  10619380,
  10973128,
  11334647,
  11704023,
  12081341,
  12466683,
  12860136,
  13261784,
  13671710,
  14090001,
];
