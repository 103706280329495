<template>
  <div id="homepage">
    <Header/>
    <section id="intro" class="section" style="--section-bg-color: transparent">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center mt-5">
            <img src="/img/idlecraft-logo-long.png" class="py-5 logo" @click="goHome"/>
          </div>
          <div class="col-12">
            <div class="d-flex justify-content-center align-items-center flex-column">
              <div class="mb-3">
                <a href="https://discord.gg/BMyngKgbNg" target="_blank" class="me-3">
                  <img src="/img/social_icons/discord.png" />
                </a>
                <a href="https://twitter.com/playidlecraft" target="_blank">
                  <img src="/img/social_icons/twitter.png" />
                </a>
              </div>
              <button class="btn btn-primary fs-2 px-5 border-dark border-opacity-50" style="--bs-btn-bg: #358e69;"
                      @click="play">Play Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-block">
        <img src="/img/bg-block.svg" alt="background image of blocks"/>
      </div>
    </section>
    <section id="fight" class="d-flex flex-column" style="--section-bg-color: rgba(44,243,144,0.3)">
      <div class="container d-flex flex-fill">
        <div class="row flex-fill">
          <div class="col-12 col-md-5 d-flex justify-content-center  flex-column">
            <h2 class="fs-1 mb-3">Fight</h2>
            <p>Pesky chickens, creepy creepers and more plague the lands, engage in combat against enemies in epic
              battles and save the realm from the evil within</p>
          </div>
          <div class="col-12 col-md-7 d-flex justify-content-center align-items-center">
            <img src="/img/homepage/fight.png" class="section-image" alt="Fight background"/>
          </div>
        </div>
      </div>
      <div class="bg-block">
        <img src="/img/bg-block.svg" alt="background image of blocks"/>
      </div>
    </section>
    <section id="craft" class="d-flex flex-column" style="--section-bg-color: rgba(200,44,243,0.3)">
      <div class="container d-flex flex-fill">
        <div class="row flex-fill flex-row-reverse">
          <div class="col-12 col-md-5 d-flex justify-content-center  flex-column">
            <h2 class="fs-1 mb-3">Craft</h2>
            <p>Don't forget to craft armor as you progress through the game and boost your attacks with powerful
              weapons. Perhaps even upgrade your tools, your fishing rod won't last forever</p>
          </div>
          <div class="col-12 col-md-7 d-flex justify-content-center align-items-center">
            <img src="/img/homepage/crafting_homepage.png" class="section-image" alt="Crafting background"/>
          </div>
        </div>
      </div>
      <div class="bg-block">
        <img src="/img/bg-block.svg" alt="background image of blocks"/>
      </div>
    </section>
    <section id="gather" class="d-flex flex-column bg-opacity-25" style="--section-bg-color: rgba(243,233,44,0.3)">
      <div class="container d-flex flex-fill">
        <div class="row flex-fill">
          <div class="col-12 col-md-5 d-flex justify-content-center  flex-column">
            <h2 class="fs-1 mb-3">Gather</h2>
            <p>Head into the Savana to gather some logs or delve deep into the volcanic mines to unearth diamonds!
              Gather resources from over 20 locations, perhaps you'll even find some hidden treasures</p>
          </div>
          <div class="col-12 col-md-7 d-flex justify-content-center align-items-center">
            <img src="/img/homepage/gathering_homepage.png" class="section-image" alt="Gathering background image"/>
          </div>
        </div>
      </div>
      <div class="bg-block">
        <img src="/img/bg-block.svg" alt="background image of blocks"/>
      </div>
    </section>
    <section id="quests" class="d-flex flex-column bg-opacity-25" style="--section-bg-color: rgba(44,77,243,0.3)">
      <div class="container d-flex flex-fill">
        <div class="row flex-fill flex-row-reverse">
          <div class="col-12 col-md-5 d-flex justify-content-center  flex-column">
            <h2 class="fs-1 mb-3">Quests</h2>
            <p>Travel alongside Thomas and Hannah as they explore the world beyond their villagers. Help out locals,
              fight off terrifying monsters, fish for treasure and more</p>
          </div>
          <div class="col-12 col-md-7 d-flex justify-content-center align-items-center">
            <img src="/img/homepage/quest_homepage.png" class="section-image" alt="Quests background"/>
          </div>
        </div>
      </div>
      <div class="bg-block">
        <img src="/img/bg-block.svg" alt="background image of blocks"/>
      </div>
    </section>

    <section id="screenshots" class="d-flex flex-column" style="--section-bg-color: rgba(44,243,197,0.3)">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <h2 class="my-5">Screenshots</h2>
          </div>
        </div>
        <div class="row flex-fill">
          <div class="col-12 col-md-4 d-flex justify-content-center overflow-hidden" v-for="index in 5" :key="index">
            <img :src="`/img/homepage/screenshots/${index}.png`" class="border-1 border-dark mb-3 mb-lg-5 w-100" :alt="`Screenshot #${index}`">
          </div>
        </div>
      </div>
      <div class="bg-block">
        <img src="/img/bg-block.svg" alt="background image of blocks"/>
      </div>
    </section>
    <footer class="bg-dark p-3 text-white-50">
      &copy; 2022+ IdleCraft, we are not affiliated or connected to Minecraft
    </footer>
  </div>
</template>
<script>
import Header from "@/components/Homepage/Header";

export default {
  name: "SplashScreen",
  components: {Header},
  data() {
    return {
      navOpen: false,
      screenshots: [
        'https://placekitten.com/g/300/300',
        'https://placekitten.com/g/300/300',
        'https://placekitten.com/g/300/300',
        'https://placekitten.com/g/300/300',
        'https://placekitten.com/g/300/300',
        'https://placekitten.com/g/300/300',
      ]
    }
  },
  methods: {
    toggleNav() {
      this.navOpen = !this.navOpen
    },
    goHome() {
      this.$router.push('/play')
    },
    play() {
      const hasSession = localStorage.getItem(process.env.VUE_APP_JWT_NAME)
      if (hasSession) {
        this.$router.push('/play')
      } else {
        this.$router.push('/login')
      }
    }
  }
}
</script>