<template>
  <div id="home-page">
    <Header/>
    <div id="auth" style="--auth-bg: url('/img/backgrounds/jgl2-bg.png')">
      <div class="form">
        <div class="contents">
          <span class="position-absolute" style="left: 25px;top:25px">
              <router-link :to="{name: 'Login'}">Back to login</router-link>
          </span>
          <h2 class="mb-3">Register</h2>
          <form @submit.prevent="registerUser" method="post" novalidate class="position-relative p-2">
            <LoadingIndicator :loading="loading" />
            <div class="input-group mb-2">
              <input type="text" class="form-control" placeholder="Username" id="register_username"
                     v-model="register.username"
                     :class="{'border-danger': errors.username}">
              <div class="text-danger small w-100" v-if="errors.username">{{ errors.username }}</div>
            </div>

            <div class="input-group mb-2">
              <input type="text" class="form-control" placeholder="Email" id="register_email"
                     v-model="register.email"
                     :class="{'border-danger': errors.email}">
              <div class="text-danger small w-100" v-if="errors.email">{{ errors.email }}</div>
            </div>

            <div class="input-group mb-2">
              <input type="password" class="form-control" placeholder="Password" id="register_password"
                     v-model="register.password"
                     :class="{'border-danger': errors.password}">
              <div class="text-danger small w-100" v-if="errors.password">{{ errors.password }}</div>
            </div>

            <div class="input-group mb-2">
              <input type="password" class="form-control" placeholder="Password"
                     id="register_password_confirm"
                     v-model="register.password_confirmation"
                     :class="{'border-danger': errors.password_confirmation}">
              <div class="text-danger small w-100" v-if="errors.password_confirmation">{{
                  errors.password_confirmation
                }}
              </div>
            </div>

            <button class="btn w-100 btn-login btn-primary" type="submit">
              Register
            </button>

            <h4 class="text-muted text-center py-3">or</h4>

            <button class="btn w-100 btn-outline-primary mb-4 bg-white" @click.prevent="playAsGuest">
              Register as a Guest
            </button>
          </form>
        </div>
        <div class="bg">

        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Header from "@/components/Homepage/Header";
import LoadingIndicator from "@/components/LoadingIndicator";

export default {
  name: 'Register',
  components: {LoadingIndicator, Header},
  data: () => {
    return {
      register: {
        username: '',
        password: '',
        password_confirmation: '',
        email: ''
      },
      errors: {},
      loading: false,
    }
  },
  methods: {
    registerUser() {
      if (this.loading) return;
      this.loading = true;
      this.$socket.emit("guest:register", this.register)
    },
    playAsGuest() {
      if (this.loading) return;
      this.loading = true;
      this.$socket.emit('guest:register guest')
    }
  },
  sockets: {
    'register error'(errors) {
      this.errors = {...errors};
      this.loading = false;
    },
  }
}
</script>
