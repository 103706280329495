import {createStore} from 'vuex'
import {UserStore} from "./UserStore";
import {ConfigStore} from "@/store/ConfigStore";
import {LayoutStore} from "@/store/LayoutStore";
import {ChatStore} from "@/store/ChatStore";
import {CombatStore} from "@/store/CombatStore";
import {MarketplaceStore} from "@/store/MarketplaceStore";
import {UserProfileStore} from "@/store/UserProfileStore";
import {ItemProfileStore} from "@/store/ItemProfileStore";

export default createStore({
  modules: {
    user: UserStore,
    config: ConfigStore,
    layout: LayoutStore,
    chat: ChatStore,
    combat: CombatStore,
    marketplace: MarketplaceStore,
    userProfiles: UserProfileStore,
    itemProfileStore: ItemProfileStore,
  },
})
