export const MarketplaceStore = {
  namespaced: true,
  state: () => ({
    listings: [],
    your_listings: [],
    categories: [],
  }),
  getters: {
    listings: (state) =>  state.listings.sort((a,b) => a.price - b.price),
    your_listings: (state) => state.your_listings,
    categories: (state) => state.categories,
  },
  mutations: {
    SOCKET_MARKETPLACE_LISTINGS(state, data) {
      state.listings = data;
    },
    SOCKET_MARKETPLACE_LISTING_DELETE(state, data) {
      state.listings = state.listings.filter(listing => listing.id !== data)
    },
    SOCKET_MARKETPLACE_LISTING_UPDATE(state, data) {
      const listing = state.listings.findIndex(listing => listing.id === data.id);
      state.listings[listing] = data;
    },
    SOCKET_MARKETPLACE_LISTING_ADD(state, data) {
      state.listings.push(data);
    },
    SOCKET_MARKETPLACE_OWN_LISTINGS(state, data) {
      state.your_listings = data;
    },
    SOCKET_MARKETPLACE_CANCEL_LISTING(state, data) {
      state.your_listings = state.your_listings.filter(listing => listing.id !== data)
    },
    SOCKET_MARKETPLACE_CATEGORIES(state, data) {
      state.categories = data;
    }
  }
}