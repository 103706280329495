export const UserProfileStore = {
  namespaced: true,
  state: () => ({
    users: [],
  }),
  getters: {
    profiles: (state) => (profile) => {
      return state.users.find(u => {
        return u.username === profile
      });
    }
  },
  mutations: {
    SOCKET_LOAD_PROFILE_BIT(state, data) {
      state.users.push(data);
    },
  }
}