<template>
  <div id="home-page">
    <Header />
    <div id="auth">
      <div class="form">
        <div class="contents">
          <h2 class="mb-3">Login to IdleCraft</h2>

          <form @submit.prevent="loginUser" method="post" novalidate class="position-relative p-2">
            <LoadingIndicator :loading="loading" />
            <label for="email" class="form-label">Email</label>
            <div class="input-group mb-2">
              <input type="text" class="form-control" placeholder="Email" id="email"
                     v-model="login.username"
                     :class="{'border-danger': errors.username}">
              <div class="text-danger small w-100" v-if="errors.username">{{ errors.username }}</div>
            </div>

            <label for="password" class="form-label">Password</label>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="Password" id="password"
                     v-model="login.password"
                     :class="{'border-danger': errors.password}">
              <div class="text-danger small w-100" v-if="errors.password">{{ errors.password }}</div>
            </div>

<!--            <label for="code" class="form-label">2 Factor Code (If enabled)</label>-->
<!--            <div class="input-group mb-3">-->
<!--              <input type="number" class="form-control" placeholder="2fa code" id="code"-->
<!--                     v-model="login.code"-->
<!--                     :class="{'border-danger': errors.code}">-->
<!--              <div class="text-danger small w-100" v-if="errors.code">{{ errors.code }}</div>-->
<!--            </div>-->

            <button class="btn w-100 btn-primary mb-4" type="submit">
              Login
            </button>

            <button class="btn w-100 btn-outline-primary mb-4 bg-white" @click.prevent="playAsGuest">
              Play as Guest
            </button>

            <router-link class="btn w-100 btn-login" :to="{name: 'Register'}">
              Register
            </router-link>
          </form>
        </div>
        <div class="bg">

        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
</style>
<script>

import LoadingIndicator from "../components/LoadingIndicator";
import Header from "@/components/Homepage/Header";
export default {
  name: 'Login',
  components: {Header, LoadingIndicator},
  data: () => {
    return {
      login: {
        username: '',
        password: '',
        code: '',
      },
      register: {
        username: '',
        password: '',
        password_confirmation: ''
      },
      errors: [],
      loading: false,
    }
  },
  methods: {
    loginUser: function () {
      if(this.loading)
        return;

      this.loading = true;
      this.$socket.emit("guest:login", this.login);
    },
    playAsGuest() {
      if(this.loading) return;

      if(confirm("Guest accounts only last 7 days, to save your progress you will need to register")) {
        this.loading = true;
        this.$socket.emit('guest:register guest')
      }
    }
  },
  sockets: {
    'login error'(errors) {
      this.errors = errors
      this.loading = false;
    },
    'register error'(errors) {
      this.errors = errors;
      this.loading = false;
    },
  }
}
</script>
