export const ItemProfileStore = {
  namespaced: true,
  state: () => ({
    items: [],
  }),
  getters: {
    items: (state) => (hash) => {

      const split = hash.split('|');

      const user_id = Number(split[0].replace('#', ''))
      const item_id = Number(split[1]);
      const item_hash = split[2];


      return state.items.find(u => {
        return u.id === item_id && u.character_id === user_id && u.item_hash === item_hash;
      });
    }
  },
  mutations: {
    SOCKET_LOAD_ITEM_BIT(state, data) {
      if (data)
        state.items.push(data);
    },
  }
}