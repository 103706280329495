import {createRouter, createWebHistory} from 'vue-router'
import Register from "@/views/Register";
import Login from "@/views/Login";
import Splash from "@/views/Splash";

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: Splash
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/play',
    name: 'Play',
    component: () => import(/* webpackChunkName "play" */ '../views/play/Play.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName "Home" */ '../views/play/Home')
      },
      {
        path: 'woodcutting',
        name: 'Woodcutting',
        component: () => import('../views/play/skills/Woodcutting')
      },
      {
        path: 'mining',
        name: 'Mining',
        component: () => import('../views/play/skills/Mining')
      },
      {
        path: 'fishing',
        name: 'Fishing',
        component: () => import('../views/play/skills/Fishing')
      },
      {
        path: 'combat',
        name: 'Combat',
        component: () => import('../views/play/skills/Combat')
      },
      {
        path: 'crafting',
        name: 'Crafting',
        component: () => import('../views/play/skills/Crafting')
      },
      {
        path: 'smithing',
        name: 'Smithing',
        component: () => import('../views/play/skills/Smithing')
      },
      // {
      //   path: 'equipment',
      //   name: 'Equipment',
      //   component: () => import('../views/loggedin/Equipment')
      // },
      {
        path: 'cooking',
        name: 'Cooking',
        component: () => import('../views/play/skills/Cooking')
      },
      {
        path: 'refining',
        name: 'Refining',
        component: () => import('../views/play/skills/Refining.vue')
      },
      {
        path: 'adventuring',
        name: 'Adventuring',
        component: () => import('../views/play/skills/Adventuring')
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('../views/play/Settings')
      },
      {
        path: 'faq',
        name: 'FAQ',
        component: () => import('../views/play/FAQ')
      },
      {
        path: 'logout',
        name: 'Logout',
        component: () => import('../views/play/FAQ')
      },
      {
        path: 'skin',
        name: 'SkinChooser',
        component: () => import('../views/play/SkinChooser')
      },
      {
        path: 'hiscores',
        name: 'Hiscores',
        component: () => import('../views/play/Hiscores')
      },
      {
        path: 'marketplace',
        component: () => import('../views/play/marketplace/Marketplace'),
        children: [
          {
            path: '',
            name: 'MarketplaceHome',
            component: () => import('../views/play/marketplace/Home.vue')
          },
          {
            path: 'history',
            name: 'MarketplaceHistory',
            component: () => import('../views/play/marketplace/History.vue')
          },
          {
            path: ':category',
            name: 'MarketplaceListings',
            component: () => import('../views/play/marketplace/Listings.vue')
          },
          {
            path: 'sell',
            name: 'MarketplaceSellItem',
            component: () => import('../views/play/marketplace/SellItem')
          },
          {
            path: 'your-listings',
            name: 'MarketplaceYourListings',
            component: () => import('../views/play/marketplace/YourListings')
          },
        ],

      },

      {
        path: 'debug',
        name: 'DebugPage',
        component: () => import('../views/play/Debug.vue')
      },
      {
        path: 'quest',
        name: 'Quest',
        component: () => import('../views/play/Quest')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


router.beforeEach((to, from, next) => {
  let loggedOutRoutes = [
    'Login',
    'Register',
    'Splash',
  ];

  if (localStorage.getItem(process.env.VUE_APP_JWT_NAME) === null && to.path.includes('play')) {
    router.push({
      name: "Login"
    })
  } else if (localStorage.getItem(process.env.VUE_APP_JWT_NAME) !== null && loggedOutRoutes.indexOf(to.name) > -1) {
    router.push({
      name: 'Mining'
    })
  }

  next();
  // if (localStorage.getItem(process.env.VUE_APP_JWT_NAME) === null && loggedOutRoutes.indexOf(to.name) !== -1) {
  //   router.push({
  //     name: 'Splash'
  //   });
  // } else {
  //   next();
  // }
  // Check if logged in
  // if (localStorage.getItem(process.env.VUE_APP_JWT_NAME) !== null) {
  //   // If they are logged in proceed, do permissions later
  //   if (loggedOutRoutes.indexOf(to.name) !== -1)
  //     router.push({
  //       name: 'Login'
  //     });
  //   else
  //     next();
  // } else {
  //   // If they have no token
  //
  //   if (loggedOutRoutes.indexOf(to.name) === -1)
  //     router.push({
  //       name: 'Login'
  //     });
  //   else
  //     next();
  // }
});

export default router
