import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueSocketIO from "vue-socket.io";
import {io} from "socket.io-client";
import FloatingVue from "floating-vue";
import {Cloudinary} from "@cloudinary/base";
import UserProfile from "@/components/chat/UserProfile.vue";

import "@/assets/style/app.scss";
import Vue3DraggableResizable from "vue3-draggable-resizable";
import Item from "@/components/items/Item.vue";

const socketConnection = io(process.env.VUE_APP_SOCKET_CONNECTION, {
  transports: ['websocket']
});

window.$cld = new Cloudinary({
  cloud: {
    cloudName: process.env.VUE_APP_CLOUDINARY
  }
});


const app = createApp(App)
  .use(new VueSocketIO({
    connection: socketConnection,
    debug: false, //process.env.VUE_APP_DEBUG_MODE || false,
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_',
    },
    options: {
      transports: ['websocket']
    }
  }))
  .use(Vue3DraggableResizable)
  .use(FloatingVue)
  .use(store)
  .use(router);

app.component('user-profile', UserProfile)
app.component('item', Item)
app.mount('#app')
