export const ChatStore = {
  namespaced: true,
  state: () => ({
    messages: [],
    index: 101,
    message: '',
  }),
  mutations: {
    SOCKET_CHAT_HISTORY(state, data) {
      state.messages = data.reverse();
    },
    SOCKET_NEW_CHAT_MESSAGE(state, data) {
      let messages = state.messages;

      if(state.messages.length > 100)
        messages.shift();

      messages.push(data);

      state.messages = messages;
    },
    SOCKET_DELETE_MESSAGE(state, id) {
      const message = state.messages.findIndex(m => m.id === id);
      state.messages.splice(message, 1);
    },
    UPDATE_MESSAGE(state, data) {
      state.message = data;
    },
    ADD_WHISPER(state, message) {
      state.message = `/whisper ${message.trim()} ${state.message} `
    },
    ADD_MENTION(state, message) {
      state.message = `${state.message} @${message} `
    },
    ADD_ITEM(state, item) {
      state.message = `${state.message} #${item.character_id}|${item.id}|${item.item_hash}`.trimStart()
    }
  },
  getters: {
    messages(state) {

      return state.messages
    },
    index(state) {
      return state.index
    },
    message: (state) => state.message
  },

}