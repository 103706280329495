<template>
  <div>
    <router-view />
    <div class="w-100 h-100 position-absolute top-0 z-index-110 bg-black d-flex justify-content-center align-items-center flex-column" v-if="updateExists">
      <img src="/img/homepage/logo.png" class="w-50" style="max-width:250px;" />
      <p class="text-white-50">
        An update is available
      </p>
      <button @click="refreshApp" class="btn btn-primary">
        Click to update
      </button>
    </div>
  </div>
</template>

<script>
import update from "@/mixins/update";

export default {
  name: "App",
  mixins: [update],
  sockets: {
    connect() {

      this.disconnected = false;

      if (localStorage.getItem(process.env.VUE_APP_JWT_NAME))
        this.$socket.emit("guest:auth", {
          token: localStorage.getItem(process.env.VUE_APP_JWT_NAME)
        })
    },
  }
}
</script>

<style lang="scss">
#app {
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
