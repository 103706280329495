export const CombatStore = {
  namespaced: true,
  state: () => ({
    inCombat: false,
    friendlyTeam: [],
    enemies: [],
    stage: null
  }),
  getters: {
    enemies: (state) => state.enemies,
    inCombat: (state) => state.inCombat,
    friendlyTeam: (state) => state.friendlyTeam,
    stage: (state) => state.stage,
  },
  mutations: {
    SOCKET_ENTER_COMBAT(state, data) {
      state.inCombat = true;
      state.friendlyTeam = data.friendlyTeam;
      state.enemies = data.enemyTeam;
      state.stage = 'fighting';
    },
    SOCKET_PLAYER_ATTACK(state, data) {
      const monster = state.enemies.findIndex(i => i.id === data.enemy.id);
      data.enemy.active = true;
      state.enemies[monster] = data.enemy;
    },
    SOCKET_STOP_SKILL(state) {
      state.friendlyTeam = [];
      state.enemies = [];
      state.inCombat = false;
    },
    SOCKET_DEAD(state) {
      state.inCombat = false;
      state.friendlyTeam = [];
      state.enemies = [];
    },
    SOCKET_COMBAT_FINISHED(state) {
      setTimeout(() => {
        state.stage = 'waiting for fight';
      }, 2000)
    },
    SOCKET_MONSTER_ATTACK(state, attack) {
      // const player = state.friendlyTeam.findIndex(i => i.id === data.enemy.id);
      for (const player in state.friendlyTeam) {
        state.friendlyTeam[player] = attack.enemy
      }
    },
    SOCKET_CHANGE_STAGE(state, stage) {
      state.stage = stage;
    }
  }
}