export const ConfigStore = {
  namespaced: true,
  state: () => ({
    zones: [],
    players_online: 0,
    quest: null
  }),
  mutations: {
    SOCKET_CONFIG_SET_SKILLS(state, data) {
      state.zones = data;
    },
    SOCKET_PLAYERS_ONLINE(state, data) {
      state.players_online = data;
    },
    SOCKET_START_QUEST(state, data) {
      state.quest = data.quest;
    },
    SOCKET_QUEST_CONFIG(state, data) {
      state.quest = data;
    },
    SOCKET_RESET_QUEST(state) {
      state.quest = null;
    },
    SOCKET_FINISH_QUEST(state) {
      state.quest = null;
    }
  },
  getters: {
    quest(state) {
      return state.quest;
    },
    zones(state) {
      return state.zones
    }
  }

}