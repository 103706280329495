<template>
  <div class="d-flex" ref="skillIcon">
    <div class="d-flex">
      <img :src="getImage" class="mc-item-image" :style="[`--size: ${size}px`]" />
    </div>
  </div>
</template>
<script>
import store from '../store'
import {startCase} from "lodash";

export default {
  name: 'McIcon',
  data() {
    return {
      store: store.state,
      tool: null,
      toolChanges: [10, 20, 40, 60, 80]
    }
  },
  props: {
    icon: {
      type: String,
      required: false
    },
    size: {
      type: Number,
      default: 36
    },
    skill: {
      type: String,
      required: false,
    },
    custom: {
      type: Boolean,
      required: false,
      default: false
    },
    animate: {
      type: Boolean,
      default: false,
      required: false,
    }
  },

  mounted() {
    this.tool = this.getSkillClass();
  },

  watch: {
    'getSkillLevel'(next) {

      if(!this.animate)
        return;

      const classAnimate = this.toolChanges.includes(next) ? 'animate-tool-change' : 'animate-level-up';

      this.$refs['skillIcon'].classList.add(classAnimate)

      setTimeout(() => {
        this.$refs['skillIcon'].classList.remove(classAnimate)
      }, 2000)

      setTimeout(() => {
        this.getSkillClass()
      }, 1000)
    }
  },

  methods: {

    getSkillClass() {

      if(this.icon) return this.icon;

      let tool = null,
        current_level = this.getSkillLevel,
        level = null;

      switch (this.skill) {
      case 'mining':
        tool = 'pickaxe';
        break;
      case 'woodcutting':
        tool = 'axe';
        break;
      case 'fishing':
        tool = 'fishing rod';
        break;
      case 'crafting':
        return 'CraftingSkillIcon'
      case 'smithing':
        return 'SmithingSkillIcon'
      case 'cooking':
        return 'CookingSkillIcon'
      case 'adventuring':
        tool = 'boots';
        break;
      case 'combat':
        tool = 'sword';
        break;
      case 'refining':
        return 'RefiningSkillIcon';
      }

      if (current_level < 10)
        level = tool === 'boots' ? 'leather' : 'wooden';
      else if (current_level < 20)
        level = tool === 'boots' ? 'iron' : 'stone';
      else if (current_level < 40)
        level = 'iron'
      else if (current_level < 60)
        level = 'golden';
      else if(current_level < 80)
        level = 'diamond'
      else
        level = 'netherite'

      return this.tool = `${level} ${tool}`;
    },
  },
  computed: {
    getSkillLevel() {
      return this.store.user.character.UserLevel[`${this.skill}_level`]
    },
    getImageClass() {
      return this.tool || `icon-${this.icon}`
    },
    getImage() {
      if(this.skill === 'total_level') {
        return `/img/items/EpicEssence.png`
      }
      const name = this.getImageClass;
      return `/img/items/${startCase(name).replaceAll(' ', '')}.png`
    },
    getIconSizeClass() {
      return `icon-size-${this.size}`
    },
    isCustomIcon() {
      return this.custom === true ? 'custom-icon' : '';
    },
  }
}
</script>
